/*
    * ======================== *
    * Define Vars *
    * ======================== *
*/


/*
    * ======================== *
    * Import _modernizr.js *
    * ======================== *
*/
require('./is-webp.js');
require('./counter.js');


/*
    * ======================== *
    * Imports Yarn packages using CommonJS modules import method  *
    * ======================== *
*/
const $ = require('jquery');
const backstretch = require('jquery-backstretch');
const hammerjs = require('hammerjs');
const fancybox = require("@fancyapps/fancybox");
const Flickity = require("flickity-fade");
const cssua = require("cssuseragent");
import tippy from 'tippy.js';

// Leaflet
// const L = require('leaflet');
// require("leaflet-providers");

// Lazy Load
const polyfillObserver = require('intersection-observer');
const lozad = require('lozad');
/*
    * end/ Imports Yarn packages *
*/


/*
    * ======================== *
    * Launch native javascript plugin *
    * ======================== *
*/
// Wowjs
const WOW = require('wowjs');
window.wow = new WOW.WOW({
    live: false
});
window.wow.init();

// Fastclick
const FastClick = require('fastclick');
FastClick.attach(document.body);

// LazyLoad on images with Lozad.js
const imgObserver = lozad('[data-lazy]', {
    rootMargin: '150px 0px'
});
imgObserver.observe();

/*
    * end/ Launch native javascript plugin *
*/


function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toGMTString();
    document.cookie = cname + "=" + cvalue + "; " + expires + ";path=/";
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1);
        if (c.indexOf(name) != -1) return c.substring(name.length, c.length);
    }
    return "";
}

function checkCookie(cname) {
    var user = getCookie(cname);
    return (user != "") ? true : false;
}


/*
    * ======================== *
    * jQuery Plugins
    * ======================== *
*/
$(function () {

    /*
        -- @ LINKS @ --
    */
    // External links
    $(document).on('click', 'a[href$=".pdf"], a.external-link', function (e) {
        e.preventDefault()
        window.open($(this).attr("href"))
    })
    // Disabled links
    $(document).on('click', 'a.noLink, a[href="GOTOLINK"]', function (e) {
        e.preventDefault()
    })
    // Backtotop links
    $(document).on('click', 'a.backtotop', function (e) {
        e.preventDefault()
        $('body,html').animate({scrollTop: 0}, 250, 'swing');
    });


    /*
        -- @ COOKIES @ --
        * Show cookies block if ck cookie does not exist
    */
    var user = checkCookie('_ck');
    if (!user)
        $('#cookies').addClass('shown');

    $(document).on('click', '#cookies a.js-accept-cookie', function (e) {
        e.preventDefault();

        var isCookify = checkCookie('_ck');

        if (!isCookify)
            setCookie('_ck', 'b326b5062b2f0e69046810717534cb09', '365');

        $('#cookies').removeClass('shown')
    });


    /*
        -- @ BACKSTRETCH @ --
        * Attach responsive background-images to elements
    */
    // Launch Backstretch with lazy load
    const backstretchObserver = lozad('.backstretch', {
        rootMargin: '50px 0px',
        loaded: function (el) {
            let imgName = $(el).attr('data-img');

            if (typeof imgName !== 'undefined' && imgName.length) {

                // If brower supports WebP img, so change image extension 8) \m/
                if (Modernizr.webp && $(el).hasClass('webp')) {
                    imgName = imgName.substr(0, imgName.lastIndexOf(".")) + ".webp";
                }

                $(el).backstretch(imgName);
            }
        }
    });
    backstretchObserver.observe();


    /*
        -- @ MAIN MENU PHONE @ --
        * Show the hamburger button for main menu on phones
    */
    var isMenuOpened = false;
    var _closeMenus = function (klass) {
        $('html, body').removeClass(klass);
        $('[data-backstretch]').removeClass('shown')
        isMenuOpened = false;
    }
    var _openMenu = function (klass) {
        $('html, body').addClass(klass)
        isMenuOpened = true;
    }

    $(document).on('click', '.js-open-menu', function (e) {
        e.preventDefault()

        if (isMenuOpened)
            _closeMenus('opened-menu');
        else
            _openMenu('opened-menu');
    });

    /* MAIN MENU */
    // Click on Hamburger
    $(document).on('click', '.truncated-text-read-more', function (e) {
        e.preventDefault()

        var _parent = $(this).parent().parent().parent();
        _parent.find('.long-text').show();
        _parent.find('.shortened-text').hide();
    });
    // HammerJS
    if ($('#main-nav').length) {
        var navBottomHeader = document.getElementById('main-nav');
        var hammerBottomMenu = new Hammer(navBottomHeader);

        hammerBottomMenu.on('swiperight', function (ev) {
            _closeMenus('opened-menu');
        });
    }
    // smartphone : Open sub-menus activities
    $(document).on('click', 'a.expand-activities', function (e) {
        e.preventDefault();
        if ($(this).next().is(':visible'))
            $(this).next().stop(true, true).slideUp('fast')
        else
            $(this).next().stop(true, true).slideDown('fast')
    });

    // Event Activities menu : Show activity image
    $(document).on('mouseenter', '.menu-event a', function (e) {
        let _src = $(this).data('img');
        let _back = $('[data-backstretch]')

        if (_src == '' || _src === undefined) {
            _back.removeClass('shown');
        } else {
            var panel = _back.backstretch(_src)
            panel.on('backstretch.after', function (e, instance, index) {
                _back.addClass('shown');
            })
        }
    })


    /* DETAIL PAGES MENUS : Used in typeActivity page (Parc Aventures par exemple) */
    // Click on Hamburger
    $(document).on('click', '.js-open-type-menu', function (e) {
        e.preventDefault();

        if (isMenuOpened)
            _closeMenus('opened-type-menu');
        else {
            _closeMenus('opened-menu');
            _openMenu('opened-type-menu');
        }
    });
    // HammerJS
    if ($('#type-menu').length) {
        var navTypeMenu = document.getElementById('type-menu');
        var typeMenu = new Hammer(navTypeMenu);

        typeMenu.on('swiperight', function (ev) {
            _closeMenus('opened-type-menu');
        });
    }


    /*
     -- @ FLICKITY PLUGIN @ --
        * Used in:
            - Homepage (shops)
            - Event Homepage
            - Outdoor list (liste des activités par métier dans Outdoor)
            - Outdoor Activité Détail (slider d'images en haut)
            - SkiSchool Activité Détail (slider d'images en haut)
            - Shop detail page
    */
    if ($('.main-carousel').length) {

        var galleryElems = document.querySelectorAll('.main-carousel');

        galleryElems.forEach(galleryElem => {

            let flkty = new Flickity(galleryElem, {
                contain: true,
                lazyLoad: true,

                // Player
                autoPlay: 55000, // Move each x miliseconds
                pauseAutoPlayOnHover: true, // Pause on hover ?

                // Slider animations
                selectedAttraction: 0.11, // Animation speed
                friction: 0.6,

                // Arrows
                prevNextButtons: ($(galleryElem).find('.carousel-cell').length > 1) ? true : false,
                pageDots: false
            });

            // If homepage
            if ($('.wrap-shop-infos').length) {
                // When slide is finished, show shop Infos & links
                flkty.on('settle', function (index) {
                    $(flkty.selectedElement).find('.wrap-shop-infos').addClass('shown');
                });
                // DragMove or Change = Hide Shop infos and link
                flkty.on('dragMove', function (event, pointer, moveVector) {
                    $(flkty.selectedElement).find('.wrap-shop-infos').removeClass('shown');
                });
                flkty.on('change', function (index) {
                    $(flkty.selectedElement).find('.wrap-shop-infos').removeClass('shown');
                });
            }

            // When slider is loaded, hide loader and show slider
            $(galleryElem).prev().fadeOut('fast', function () {
                $(this).remove();
                $(galleryElem).addClass('shown');

                // Show First Shop infos
                $(flkty.selectedElement).find('.wrap-shop-infos').addClass('shown');
            });

        })
    }


    /*
     -- @ SCROLL TABS IN DETAIL PAGES @ --
        * Used in:
            - Activiy detail
    */

    // Gestion du scroll et affichage de la flèche de droite
    if ($('body:not(.service-page) #scroll-tabs').length) {

        // Vars
        var
            _tabsWrapper = document.getElementById("wrap-scroll-tabs"),
            _tabsWidth = document.getElementById("scroll-tabs").offsetWidth,
            _tabsWrapperLeftPosition = 0,
            _rightPoint = _tabsWrapperLeftPosition + _tabsWrapper.offsetWidth;


        // Si les tabs tiennent dans la largeur de l'écran, on cache la flèche de droite
        if (_tabsWrapper.offsetWidth >= _tabsWidth) {
            $('.tabs-arrow').addClass('closed');
        }

        // Scroll listener
        _tabsWrapper.addEventListener('scroll', function (e) {

            // Tabs Left position
            _tabsWrapperLeftPosition = _tabsWrapper.scrollLeft;
            _rightPoint = _tabsWrapperLeftPosition + _tabsWrapper.offsetWidth;
            _tabsWidth = document.getElementById("scroll-tabs").offsetWidth;

            // diff entre la droite du scrool et la largeur des tabs
            var _diff = parseInt(_tabsWidth - _rightPoint);

            // Si on est à moins de 50px du bord droit, on cache la flèche
            if (_diff < 50) {
                $('.tabs-arrow').addClass('closed');
            } else {
                $('.tabs-arrow').removeClass('closed');
            }

        });
    }

    $(document).on('click', 'body:not(.service-page) #scroll-tabs a', function (e) {
        e.preventDefault();

        var _link = $(this),
            _rel = $(this).data('rel');

        if (!_link.hasClass('active')) {
            $('#scroll-tabs a').add('.wrap-content > .item').removeClass('active');
            $(_rel).add(_link).addClass('active');
        }
    })

    $(".btn-book-online").on('click', function (e) {
        e.preventDefault();

        $('.wrap-content > .item').removeClass("active");
        $('.wrap-content > .activity-bookingiframe').addClass("active");
        $('body,html').animate({scrollTop: $('.wrap-tabs-content').offset().top}, 250, 'swing');
    })

    // URLS for shop-page with hashes in url
    if ($('.shop-page').length && window.location.hash) {

        let hash = window.location.hash.substring(1);
        $('#scroll-tabs a[data-rel=".shop-' + hash + '"]').trigger('click');
        // setTimeout(function() {
        //     $('body,html').animate({scrollTop: $('.wrap-tabs-content').offset().top}, 250, 'swing')

        // },500);

    }


    /*
        -- @ EVENT TABS @ --
            * Used in :
                - "Votre evenement" pages
                -
    */
    $(document).on('click', '.tabs a', function (e) {
        e.preventDefault();

        var _link = $(this),
            _rel = $(this).data('rel');

        if (!_link.hasClass('active')) {
            $('.tabs a, .wrap-obj-value > div').removeClass('active');
            $(_rel).add(_link).addClass('active');
        }
    })


    /*
        -- @ ACCORDEONS @ --
            * Used in :
                - "ELPRO Event" rubrique
    */
    $(document).on('click', '[data-js-accordeon] h2 a', function (e) {
        e.preventDefault();

        var _link = $(this),
            _accordeon = $(this).parent().parent();

        $(_accordeon).toggleClass('shown')
    });


    /*
        -- @ TippyJS Plugin @ --
    */
    tippy('.tippyjs', {
        'placement': 'top'
    });


    /*
     -- @ FANCYBOX PLUGIN @ --
        * Used in:
            - Homepage : Open fullscreen video
    */
    $("[data-fancybox]").fancybox({
        loop: true,
        infobar: false,
        buttons: [
            "zoom",
            "share",
            "fullScreen",
            "close"
        ],
        transitionEffect: "slide",
        protect: true,
        lang: "fr",
        i18n: {
            fr: {
                CLOSE: "Fermer",
                NEXT: "Suivant",
                PREV: "Précédent",
                ERROR: "La photo ne peut pas être chargée, merci de réessayer plus tard.",
                FULL_SCREEN: "Plein écran",
                DOWNLOAD: "Télécharger",
                SHARE: "Partager sur les réseaux sociaux",
                ZOOM: "Zoomer la photo"
            }
        }
    });


    /*
        -- @ COUNTER @ --
        Used in :
            - Event Homepage
    */
    const counterObserver = lozad('[data-counter-to]', {
        rootMargin: '0px',
        loaded: function (el) {
            var to = $(el).data('counter-to');

            $(el).countTo({
                from: 0,
                to: to,
                speed: 1500,
                refreshInterval: 10
            });
        }
    });
    counterObserver.observe();

});
/*
    * end/ jQuery Plugins
*/
